import React from 'react';
import './App.css';
import Page from './Page';

function App() {
    return (
    <div className="App">
      <h1>Markettaj</h1>
      <Page/>
      
    </div>
  );
}

export default App;
